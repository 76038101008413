import React, { useEffect, useRef, useState } from "react";
import "./change_password.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import { useChangePasswordMutation } from "../../redux/reducers/doctor/doctorApiSlice";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { logOut, selectCurrentToken } from "../../redux/reducers/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";

const ChangePassword = () => {
  const pwdRef = useRef();
  const [pwd, setPwd] = useState("");
  const [verifyPwd, setVerifyPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [verified, setVerified] = useState(false);
  const [changePassword, { isLoading }] = useChangePasswordMutation();

  const token = useSelector(selectCurrentToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  useEffect(() => {
    pwdRef.current.focus();
  }, []);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const cPwd = await changePassword({ token: token, verifyPwd: verifyPwd }).unwrap();
      if (!cPwd?.status) {
        Swal.fire({
          position: "top-end",
          icon: "info",
          title: "Change Password Unsuccessful",
          text: "Please try after some time...",
          showConfirmButton: false,
          timer: 3500,
        });
      }
      if (cPwd?.status && cPwd?.msg !== "") {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Change Password",
          text: "Your password is changed, please login with new password",
          showConfirmButton: false,
          timer: 3500,
        });
        dispatch(logOut());
        setVerifyPwd("");
        setPwd("");
        navigate("/");
      }
    } catch (err) {
      if (!err?.originalStatus) {
        // isLoading: true until timeout occurs
        setErrMsg("No Server Response");
      } else if (err.originalStatus === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err.originalStatus === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
    }
  };

  useEffect(()=>{
    if(pwd.length === 0){
      setVerifyPwd("")
    }
  },[pwd])

  useEffect(()=>{
    if(pwd.length >= 8 && verifyPwd.length >= 8 && pwd.length === verifyPwd.length && pwd === verifyPwd){
      setVerified(true)
    }else{
      setVerified(false)
    }
  },[pwd,verifyPwd])

  let page = (
    <div className="changePassword">
      <Sidebar />
      <div className="changePasswordContainer">
        <Navbar />
        <div className="title">
          <h1>
            Change Password
          </h1>
        </div>
        <div className="body">
        <form onSubmit={handleSubmit}>
            <div className="formInput">
              <label for="">New Password*</label>
              <input
                type="text"
                required
                ref={pwdRef}
                value={pwd}
                onChange={(e) => setPwd(e.target.value)}
                autoComplete="off"
                minLength={8}
                maxLength={50}
                className="newPassword"
              />
              {verified ? <CheckRoundedIcon className="icon" /> : ""}
            </div>
            <div className="formInput">
              <label for="">Verify Password*</label>
              <input
                type="text"
                required
                onChange={(e) => setVerifyPwd(e.target.value)}
                value={verifyPwd}
                minLength={8}
                maxLength={50}
                disabled={(pwd.length <= 0)}
                className="verifyPassword"
              />
              {verified ? <CheckRoundedIcon className="icon" /> : ""}
            </div>
            <button disabled={verified && !isLoading ? false : true}>{isLoading ? 'Processing...': 'Save'}</button>
          </form>
          
        </div>
      </div>
    </div>
  );

  return isLoading ? <Loader /> : page;

};

export default ChangePassword;
