import React, { useEffect, useState } from "react";
import "./doctor_list.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import Datatable from "../../components/Datatable/Datatable";
import { useChangeDoctorActiveStatusMutation, useDoctorListQuery } from "./../../redux/reducers/doctor/doctorApiSlice.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faHouseMedical,
  faPeopleGroup,
  faPhone,
  faStethoscope,
} from "@fortawesome/free-solid-svg-icons";
import TransitionModal from "../../components/Modal/Modal";
import Swal from "sweetalert2";

const DoctorList = () => {
  let [docList, setDocList] = useState([]);
  const {
    data, 
    isLoading : listLoading,
    isSuccess : listSuccess,
    isError: listError,
    error,
    refetch,
    isFetching
  } = useDoctorListQuery('doctorList',{refetchOnMountOrArgChange: true});
  const [changeDoctorActiveStatus, {isLoading : statusLoading, isSuccess : statusSuccess, isError}   ] = useChangeDoctorActiveStatusMutation();

  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");

  const openCouncilDetails = ({name,council_name,council_reg_no}) => {
    let councilDetailsTitle = (
      <div className="modalTitleList">
        <FontAwesomeIcon icon={faPeopleGroup} className="icon" />
        <span>{`${name}'s Council Details`}</span>
      </div>
    )
    setModalTitle(councilDetailsTitle);
    let councilDetailsList = (
      <div className="modalBodyList">
        <ul>
          <li>
            <label>Council Name: </label>
            <span>{council_name}</span>
          </li>
          <li>
            <label>Council Registration Number: </label>
            <span>{council_reg_no}</span>
          </li>
        </ul>
      </div>
    )
    setModalBody(councilDetailsList);
    setOpenModal(true)
  }

  const openHospitalDetails = ({name,hospital_name,hospital_address,hospital_pincode,hospital_no}) => {
    let hospitalDetailsTitle = (
      <div className="modalTitleList">
        <FontAwesomeIcon icon={faHouseMedical} className="icon" />
        <span>{`${name}'s Hospital Details`}</span>
      </div>
    )
    setModalTitle(hospitalDetailsTitle);
    let hospitalDetailsList = (
      <div className="modalBodyList">
        <ul>
          <li>
            <label>Hospital Name: </label>
            <span>{hospital_name}</span>
          </li>
          <li>
            <label>Hospital Address: </label>
            <span>{hospital_address}</span>
          </li>
          <li>
            <label>Hospital Pincode: </label>
            <span>{hospital_pincode}</span>
          </li>
          <li>
            <label>Hospital Contact No: </label>
            <span>{hospital_no}</span>
          </li>
        </ul>
      </div>
    )
    setModalBody(hospitalDetailsList);
    setOpenModal(true)
  }

  const doctorListCols = [
    { field: "doctor_id", headerName: "ID", width: 20, key: "id"},
    {
      field: "name",
      headerName: "Personal Details",
      width: 180,
      renderCell: (params) => {
        return (
          <div className="cellWithIconAndList" key={params.row.id}>
            <ul>
              <li>
                <strong>{params.row.name}</strong>
              </li>
              <li>
                <FontAwesomeIcon icon={faStethoscope} className="icon" />
                <i>{params.row.specialization}</i>
              </li>
            </ul>
          </div>
        );
      },
    },
    {
      field: "contact_detail",
      headerName: "Contact Details",
      width: 250,
      renderCell: (params) => {
        return (
          <div className="cellWithIconAndList" key={params.row.id}>
            <ul>
              <li>
                <FontAwesomeIcon icon={faEnvelope} className="icon" />
                <span>{params.row.email}</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faPhone} className="icon" />
                <span>{params.row.mob_num}</span>
              </li>
            </ul>
          </div>
        );
      },
    },
    {
      field: "work_detail",
      headerName: "Work Details",
      width: 170,
      renderCell: (params) => {
        return (
          <div className="cellWithIconAndList" key={params.row.id}>
            <ul>
              <li>
                <FontAwesomeIcon icon={faPeopleGroup} className="icon" />
                <a
                  href="javascript:void(0)"
                  style={{ cursor: "pointer" }}
                  onClick={()=> openCouncilDetails({...params.row})}
                >
                  {params.row.council_name}
                </a>
              </li>
              <li>
                <FontAwesomeIcon icon={faHouseMedical} className="icon" />
                <a
                  href="javascript:void(0)"
                  style={{ cursor: "pointer" }}
                  onClick={()=> openHospitalDetails({...params.row})}
                >
                  {params.row.hospital_name.trim() === 'All India Institute of Medical Sciences (AIIMS)' ? 'AIIMS' : params.row.hospital_name}
                </a>
              </li>
            </ul>
          </div>
        );
      },
    },
    {
      field: "doctor_status",
      headerName: "Status",
      width: 70,
      renderCell: (params) => {
        return (
          <div className="statusList" key={params.row.id}>
            <ul>
              <li>
                <div
                  className={`cellWithStatus ${
                    params.row.is_active === "Yes" ? "active" : "deactivate"
                  }`}
                >
                  <strong>
                    {params.row.is_active === "Yes" ? "Active" : "Deactive"}
                  </strong>
                </div>
              </li>
              {params.row.permission_type === "Admin" && (
                <li>
                  <div
                    className={`cellWithStatus ${
                      params.row.permission_type === "Admin"
                        ? "admin"
                        : "pending"
                    }`}
                  >
                    <strong>
                      {params.row.permission_type === "Admin" ? "Admin" : ""}
                    </strong>
                  </div>
                </li>
              )}
            </ul>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    if(listSuccess || statusSuccess || !isFetching){
      
      loadList();
    }
  }, [listSuccess, statusSuccess, !isFetching]);

  const loadList = async () => {
    try {
      let list = data.ids.map((v,i)=>{
        return data.entities[v]
      });
      setDocList(list);
    }catch (e) {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Something Went Wrong",
        text: e.message,
        showConfirmButton: true,
      });
    }
  };

  const handleDoctorStatus = async ({doctorId, op}) => {
    try{
      // debugger
      const status = await changeDoctorActiveStatus({ doctorId, op }).unwrap();
      refetch();
      if(status.status && status.status_code === 200){
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Status Changed Successfully",
          text: status.msg,
          timer: 5000,
        }).then(() => {
          
        });
      }else{
        Swal.fire({
          position: "top-end",
          icon: "warning",
          title: "Something Went Wrong",
          text: status.msg,
          timer: 5000,
        })
      }
    }
    catch(e){
      console.log('Error ',e);
    }
  }

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        {listError && <div className="tableError">{JSON.stringify(error)}</div>}
          {(listSuccess || statusSuccess || !isFetching) && <Datatable
            listSource={"doctorList"}
            listCols={doctorListCols}
            listRows={docList}
            isLoading={listLoading || statusLoading}
            actionColWidth={350}
            handleStatus={handleDoctorStatus}
          />}
          <TransitionModal
            modalOpen={openModal}
            handleClose={() => setOpenModal(false)}
            title={modalTitle}
            body={modalBody}
          />
      </div>
    </div>
  );
};

export default DoctorList;
