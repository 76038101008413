import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import Home from "./pages/Homepage/Home";
import Login from "./pages/Login/Login";
import List from "./pages/List/List";
import Single from "./pages/Single/Single";
import New from "./pages/New/New";
import RequireAuth from "./redux/reducers/auth/RequireAuth";
import DoctorList from "./pages/Doctor/DoctorList";
import NotFound from "./pages/utility/NotFound";
import { ErrorBoundary } from "react-error-boundary";
import DoctorDetail from "./pages/Doctor/DoctorDetail";
import DoctorPatientList from "./pages/Doctor/DoctorPatientList";
import PatientList from "./pages/Patient/PatientList";
import PatientDetail from "./pages/Patient/PatientDetail";
import Profile from "./pages/Doctor/Profile";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import DownloadReport from "./pages/DownloadReport/DownloadReport";

// import ForgotPassword from "./pages/ForgotPassword/forgotPassword";


function App() {

  

  return (
    <div className="App">
      <BrowserRouter>
          {/* <ErrorBoundary fallbackRender={fallbackRender} onReset={resetPage}> */}
        <Routes>
            <Route path="/*" >
              {/* public routes */}
              <Route index element={<Login />} />
              {/* protected routes */}
              {/* <Route path="forgotPassword" element={<ForgotPassword />} /> */}
              <Route element={<RequireAuth />}>
                <Route path="home" element={<Home />} />
                <Route path="profile" element={<Profile />} />
                <Route path="changePassword" element={<ChangePassword />} />
                <Route path="download/reports" element={<DownloadReport />} />
                <Route path="doctors">
                  <Route index element={<DoctorList />} />
                  <Route path=":phoneId" element={<DoctorDetail />} />
                  <Route path="patients/list/:doctorId" element={<DoctorPatientList />} />
                  <Route path="patient/:patientId" element={<PatientDetail />} />
                </Route>
                <Route path="patients">
                  <Route index element={<PatientList />} />
                  <Route path=":patientId" element={<PatientDetail />} />
                  <Route path="new" element={<New />} />
                </Route>
              </Route>
              <Route path="*" element={<Login />} />
            </Route>
        </Routes>
          {/* </ErrorBoundary> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
