import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials, logOut } from "./../reducers/auth/authSlice";
import Swal from "sweetalert2";

const baseQuery = fetchBaseQuery({
  baseUrl: (process.env.NODE_ENV === 'development') ? process.env.REACT_APP_DEV_API_URL : process.env.REACT_APP_PROD_API_URL,
  mode: "cors",
  // credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  // if (result?.error?.originalStatus === 403) {
  //     console.log('sending refresh token')
  //     // send refresh token to get new access token
  //     const refreshResult = await baseQuery('/refresh', api, extraOptions)
  //     console.log(refreshResult)
  //     if (refreshResult?.data) {
  //         const user = api.getState().auth.user
  //         // store the new token
  //         api.dispatch(setCredentials({ ...refreshResult.data, user }))
  //         // retry the original query with new access token
  //         result = await baseQuery(args, api, extraOptions)
  //     } else {

  //     }
  // }

  if (result?.data?.status_code === 302 && result?.data?.status === false) {
    console.log("TOken Expired");
    Swal.fire({
      icon: "warning",
      title: "Session Expired",
      text: "Logging You Out",
      timer: 5000,
      // showDenyButton: true,
      // showCancelButton: true,
      // confirmButtonText: 'Save',
      // denyButtonText: `Don't save`,
    }).then((result) => {
      api.dispatch(logOut());
    });
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
  tagTypes: ['DoctorList','DoctorPatientList','PatientList']
});
