import React, { useState, useRef, useEffect } from "react";
import "./login.scss";
import Topbar from "../../components/Topbar/Topbar";
import headacheImg from "./../../assets/img/headache.jpg";
import { Link, redirect } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCredentials } from "./../../redux/reducers/auth/authSlice";
import { useDoctorLoginMutation } from "./../../redux/reducers/auth/authApiSlice";
import Swal from "sweetalert2";

const Login = () => {
  const userRef = useRef();
  const errRef = useRef();
  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [phoneCheck, setPhoneCheck] = useState(false);


  const navigate = useNavigate();

  const [login, { isLoading }] = useDoctorLoginMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const checkValidPhoneNo = (inputtxt) => {
    const phoneno = /\d/;
    if((inputtxt.match(phoneno))){
      return true;
    }
    else{
      return false;
    }
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userData = await login({ user, pwd }).unwrap();
      if (!userData?.status) {
        Swal.fire({
          position: "top-end",
          icon: "info",
          title: "Login Unsuccessful",
          text: "Please Check your Mobile Number or Password",
          showConfirmButton: false,
          timer: 3500,
        });
      }
      console.log(">>>>>sdf ", userData);
      if (userData?.status && userData?.result?.permission_type !== "Admin") {
        Swal.fire({
          position: "top-end",
          icon: "warning",
          title: "UnAuthorized Login",
          text: "You are not allowed to login",
          showConfirmButton: false,
          timer: 3500,
        });
      }
      dispatch(setCredentials({ ...userData, user }));
      setUser("");
      setPwd("");
      navigate("/home");
    } catch (err) {
      console.log('>>>>>>>>>>> error',err);
      // if (!err?.originalStatus) {
      //   // isLoading: true until timeout occurs
      //   setErrMsg("No Server Response");
      // } else if (err.originalStatus === 400) {
      //   setErrMsg("Missing Username or Password");
      // } else if (err.originalStatus === 401) {
      //   setErrMsg("Unauthorized");
      // } else {
      //   setErrMsg("Login Failed");
      // }
      // errRef.current.focus();
    }
  };

  const handleUserInput = (e) => {
    // console.log(e.target.value)
    // const phoneno = /\d/;
    // if((e.target.value.match(phoneno))){
      setUser(e.target.value)
    // }
  };

  const handlePwdInput = (e) => setPwd(e.target.value);

  return (
    <div className="login">
      <Topbar />
      <div className="loginContainer">
        <div className="loginCard">
          <img src={headacheImg} alt="headache img" />
          <h1>E- Headache Dashboard</h1>
          <form onSubmit={handleSubmit}>
            <div className="formInput">
              <label for="">Mobile Number</label>
              <input
                type="text"
                required
                ref={userRef}
                value={user}
                onChange={handleUserInput}
                autoComplete="off"
                minLength={10}
                maxLength={10}
              />
            </div>
            <div className="formInput">
              <label for="">Password</label>
              <input
                type="password"
                required
                onChange={handlePwdInput}
                value={pwd}
              />
            </div>
            <div className="forgetLink">
              {/* <Link to="forgotPassword"> */}
                <label for="">Forget Password</label>
              {/* </Link> */}
            </div>
            {/* disabled={(phoneCheck) ? true : false} */}
            <button disabled={isLoading}>{isLoading ? 'Loggin in...': 'Log in'}</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
