import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";

const patientsAdapter = createEntityAdapter();

const initialState = patientsAdapter.getInitialState();

export const patientApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    patientList: builder.query({
      query: (credentials) => ({
        method: "POST",
        body: {
          name: "get_all_patient_list",
          param: {
            auth: true
          },
        },
      }),
      transformResponse: responseData => {
        const loadedPatients = responseData?.result?.patients_list?.map(patient => {
          return {...patient, id: patient.patient_id}
        });
        return patientsAdapter.setAll(initialState, loadedPatients)
      },
      providesTags: (result, error, arg) => [
        { type: 'PatientList', id: "LIST" },
        ...result.ids.map(id => ({ type: 'PatientList', id }))
      ]
    }),
    patientDetailList: builder.mutation({
      query: (data) => ({
        method: "POST",
        body: {
          name: "get_patient_detail_by_id",
          param: {
            auth: true,
            patient_id: data.patient_id
          },
        },
      }),
    }),
    changePatientActiveStatusInList: builder.mutation({
      query: (data) => ({
        method: "POST",
        body: {
          name: "get_active_deactive_patient_by_admin",
          param: {
            patient_id: data.patientId.toString(),
            op: data.op
          },
        },
      }),
      invalidatesTags: [
        { type: 'PatientList', id: "LIST" }
      ]
    }),
    totalPatientDownloadLink: builder.mutation({
      query: (data) => ({
        method: "POST",
        body: {
          name: "get_total_patient_list_download_link",
          param: {
            auth: true,
          },
        },
      }),
    }),
    totalPatientHeadacheCharacteristicsListDownloadLink: builder.mutation({
      query: (data) => ({
        method: "POST",
        body: {
          name: "get_total_patient_list_download_link",
          param: {
            auth: true,
          },
        },
      }),
    }),
  }),
});

export const { usePatientListQuery, usePatientDetailListMutation, useChangePatientActiveStatusInListMutation, useTotalPatientDownloadLinkMutation, useTotalPatientHeadacheCharacteristicsListDownloadLinkMutation } = patientApiSlice;
