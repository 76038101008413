import React, { useEffect, useState } from "react";
import "./home.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import Widget from "../../components/Widget/Widget";
import { useUserTypeListQuery } from "./../../redux/reducers/doctor/doctorApiSlice";
import Swal from "sweetalert2";
import { CircularProgress } from "@mui/material";

const Home = () => {
  let [totalAdmin, setTotalAdmin] = useState(0);
  let [totalPatient, setTotalPatient] = useState(0);
  let [totalDoctor, setTotalDoctor] = useState(0);
  let [totalHeadache, setTotalHeadache] = useState(0);

  const {
    data, 
    isLoading : listLoading,
    isSuccess : listSuccess,
    isError: listError,
    error,
    isFetching
  } = useUserTypeListQuery('userTypeList',{refetchOnMountOrArgChange: true});


  useEffect(() => {
    if(listSuccess || !isFetching){
      loadList();
    }
  }, [listSuccess, !isFetching]);

  const loadList = async () => {
    try {
      if(!data?.status){
        throw new Error(data?.msg)
      }
      if(data?.status && data?.result?.length === 0){
        throw new Error("User Type list Not Available")
      }
      setTotalAdmin(data.result.total_user_type.admin);
      setTotalDoctor(data.result.total_user_type.doctor);
      setTotalHeadache(data.result.total_user_type.headache);
      setTotalPatient(data.result.total_user_type.patient);

    }catch (e) {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Something Went Wrong",
        text: e.message,
        showConfirmButton: true,
      });
    }
  };

  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        {listError && <div className="tableError">{JSON.stringify(error)}</div>}
        <div className="widgetsContainer">
          <div className="left">
            <div className="widgets">
              {listLoading ? <CircularProgress color="secondary" /> : <Widget type="admin" value={totalAdmin} />}
              {listLoading ? <CircularProgress color="secondary" /> : <Widget type="doctor" value={totalDoctor} />}
            </div>
          </div>
          <div className="right">
            <div className="widgets">
              {listLoading ? <CircularProgress color="secondary" /> : <Widget type="patient" value={totalPatient} />}
              {listLoading ? <CircularProgress color="secondary" /> : <Widget type="headache" value={totalHeadache} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
