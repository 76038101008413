import { apiSlice } from "../../api/apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    doctorLogin: builder.mutation({
      query: (credentials) => ({
        method: "POST",
        body: {
          name: "login",
          param: {
            user_type: "doctor",
            mob: credentials.user,
            password: credentials.pwd,
            device_token: "74747474",
          },
        },
      }),
    }),
  }),
});

export const { useDoctorLoginMutation } = authApiSlice;
