import React, { useEffect, useState } from "react";
import "./navbar.scss";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import maleDoctor from "./../../assets/img/doctor_male.svg";
import femaleDoctor from "./../../assets/img/doctor_female.svg";
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';

const Navbar = () => {
  const [currentDate, setCurrentDate] = useState("");
  const [currentTime, setCurrentTime] = useState("");


  useEffect(() => {
    const int = setInterval( () => {
      let currentDate = new Date().toJSON().slice(0, 10);
      let date = new Date();
      let currentTime = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
      setCurrentDate(currentDate);
      setCurrentTime(currentTime);
    },1000);

    return () => {
      clearInterval(int);
    }
  },[currentDate])
 
  return (
    <div className="navbar">
      <div className="wrapper">
        {/* <div className='search'>
          <input type="text" placeholder='Search..' />
          <SearchRoundedIcon className='seachIcon'/>
        </div> */}
        <div className="items">
          <div className="item">
            <CalendarTodayRoundedIcon className="icon" />
            {currentDate}
          </div>
          <div className="item">
            <AccessTimeRoundedIcon className="icon" />
            {currentTime}
          </div>
          <div className="item">
            <LanguageRoundedIcon className="icon" />
            English
          </div>
          {/* <div className='item'>
            <CommentRoundedIcon className='icon' />
            <div className='counter'>2</div>
          </div>
          <div className='item'>
            <NotificationsRoundedIcon className='icon' />
            <div className='counter'>2</div>
          </div> */}
          <div className="item">
            <img src={femaleDoctor} alt="img" className="avatar" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
