import React, { useEffect, useState } from "react";
import "./doctor_list.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import Datatable from "../../components/Datatable/Datatable";
import { useChangePatientActiveStatusMutation, useDoctorPatientListQuery } from "./../../redux/reducers/doctor/doctorApiSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPerson,
  faPersonDress,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import TransitionModal from "../../components/Modal/Modal";
import Swal from "sweetalert2";
import { useParams } from "react-router";


const DoctorPatientList = () => {
  let [docPatientList, setDocPatientList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");

  const { doctorId } = useParams();

  const {
    data, 
    isLoading : listLoading,
    isSuccess : listSuccess,
    isError: listError,
    error,
    refetch,
    isFetching
  } = useDoctorPatientListQuery({doctor_id: doctorId},{refetchOnMountOrArgChange: true});
  const [changePatientActiveStatus, {isLoading : statusLoading, isSuccess : statusSuccess, isError} ] = useChangePatientActiveStatusMutation();

  
  const doctorPatientListCols = [
    { field: "patient_id", headerName: "ID", width: 20, key:"id" },
    {
      field: "name",
      headerName: "Personal Details",
      width: 180,
      renderCell: (params) => {
        return (
          <div className="cellWithIconAndList" key={params.row.id}>
            <ul>
              <li>
                <strong>{params.row.name}</strong>
              </li>
              <li>
                <FontAwesomeIcon icon={params.row.gender && params.row.gender.toLowerCase() === 'male' ? faPerson : faPersonDress} className="icon" />
                <i>{params.row.gender}</i>
              </li>
            </ul>
          </div>
        );
      },
    },
    {
      field: "contact_detail",
      headerName: "Contact Details",
      width: 230,
      renderCell: (params) => {
        return (
          <div className="cellWithIconAndList" key={params.row.id}>
            <ul>
              <li>
                <FontAwesomeIcon icon={faEnvelope} className="icon" />
                <span>{params.row.email}</span>
              </li>
              <li>
                <FontAwesomeIcon icon={faPhone} className="icon" />
                <span>{params.row.mob_num}</span>
              </li>
            </ul>
          </div>
        );
      },
    },
    {
      field: "related_detail",
      headerName: "Related Details",
      width: 230,
      renderCell: (params) => {
        return (
          <div className="cellWithIconAndList" key={params.row.id}>
            <ul>
              <li>
                <label>Age: </label>
                <span>
                  {params.row.age}
                </span>
              </li>
              <li>
              <label>Education: </label>
                <span>
                  {params.row.edu_name}
                </span>
              </li>
            </ul>
          </div>
        );
      },
    },
    {
      field: "patient_status",
      headerName: "Status",
      width: 70,
      renderCell: (params) => {
        return (
          <div className="statusList" key={params.row.id}>
            <ul>
              <li>
                <div
                  className={`cellWithStatus ${
                    params.row.is_active === "Yes" ? "active" : "deactivate"
                  }`}
                >
                  <strong>
                    {params.row.is_active === "Yes" ? "Active" : "Deactive"}
                  </strong>
                </div>
              </li>
            </ul>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if(listSuccess || statusSuccess || !isFetching ){
      loadList();
    }
  }, [listSuccess, statusSuccess, !isFetching]);

  const loadList = async () => {
    try {
      let list = data.ids.map((v,i)=>{
        return data.entities[v]
      });
      setDocPatientList(list);
    }catch (e) {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Something Went Wrong",
        text: e.message,
        showConfirmButton: true,
      });
    }
  };

  const handlePatientStatus = async ({patientId, op}) => {
    try{
      const status = await changePatientActiveStatus({ patientId, op }).unwrap();
      refetch();
      if(status.status && status.status_code === 200){
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Status Changed Successfully",
          text: status.msg,
          timer: 5000,
        })
      }else{
        Swal.fire({
          position: "top-end",
          icon: "warning",
          title: "Something Went Wrong",
          text: status.msg,
          timer: 5000,
        })
      }
    }
    catch(e){
      console.log('Error ',e);
    }
  }

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        {listError && <div className="tableError">{JSON.stringify(error)}</div>}
        {(listSuccess || statusSuccess || !isFetching) && <Datatable
          listSource={"doctorPatientList"}
          listCols={doctorPatientListCols}
          listRows={docPatientList}
          isLoading={listLoading}
          actionColWidth={330}
          handleStatus={handlePatientStatus}
        />}
        <TransitionModal
          modalOpen={openModal}
          handleClose={() => setOpenModal(false)}
          title={modalTitle}
          body={modalBody}
        />
      </div>
    </div>
  );
};

export default DoctorPatientList;
