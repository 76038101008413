import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../api/apiSlice";

const doctorsAdapter = createEntityAdapter();

const initialState = doctorsAdapter.getInitialState();

export const doctorApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    doctorList: builder.query({
      query: (credentials) => ({
        method: "POST",
        body: {
          name: "get_all_doctor_list",
          param: {
            auth: true
          },
        },
      }),
      transformResponse: responseData => {
        const loadedDoctors = responseData?.result?.doctors_list?.map(doctor => {
          return {...doctor, id: doctor.doctor_id}
        });
        return doctorsAdapter.setAll(initialState, loadedDoctors)
      },
      providesTags: (result, error, arg) => [
        { type: 'DoctorList', id: "LIST" },
        ...result.ids.map(id => ({ type: 'DoctorList', id }))
      ]
        
    }),
    doctorDetailList: builder.mutation({
      query: (data) => ({
        method: "POST",
        body: {
          name: "get_doctor_detail_by_id",
          param: {
            auth: true,
            doctor_phone_id: data.doctor_phone_id ? data.doctor_phone_id : "",
            __token: data.__token ? data.__token : ""
          },
        },
      }),
    }),
    doctorPatientList: builder.query({
      query: (data) => ({
        method: "POST",
        body: {
          name: "get_doctor_patient_list_by_doctor_id",
          param: {
            auth: true,
            doctor_id: data.doctor_id
          },
        },
      }),
      transformResponse: responseData => {
        const loadedPatients = responseData?.result?.doctor_patient_list?.map(patient => {
          return {...patient, id: patient.patient_id}
        });
        return doctorsAdapter.setAll(initialState, loadedPatients)
      },
      providesTags: (result, error, arg) => [
        { type: 'DoctorPatientList', id: "LIST" },
        ...result.ids.map(id => ({ type: 'DoctorPatientList', id }))
      ]
    }),
    userTypeList: builder.query({
      query: (data) => ({
        method: "POST",
        body: {
          name: "get_user_total_by_type",
          param: {
            auth: true
          },
        },
      }),
    }),
    changePassword: builder.mutation({
      query: (data) => ({
        method: "POST",
        body: {
          name: "change_admin_password",
          param: {
            auth: true,
            token: data.token,
            new_password: data.verifyPwd
          },
        },
      }),
    }),
    changeDoctorActiveStatus: builder.mutation({
      query: (data) => ({
        method: "POST",
        body: {
          name: "get_active_deactive_doctor_by_admin",
          param: {
            doctor_id: data.doctorId.toString(),
            op: data.op
          },
        },
      }),
      
      invalidatesTags: [
        { type: 'DoctorList', id: "LIST" }
      ]
    }),
    changePatientActiveStatus: builder.mutation({
      query: (data) => ({
        method: "POST",
        body: {
          name: "get_active_deactive_patient_by_admin",
          param: {
            patient_id: data.patientId.toString(),
            op: data.op
          },
        },
      }),
      
      invalidatesTags: [
        { type: 'DoctorPatientList', id: "LIST" }
      ]
    }),
    totalDoctorListDownloadLink: builder.mutation({
      query: (data) => ({
        method: "POST",
        body: {
          name: "get_total_doctor_list_download_link",
          param: {
            auth: true,
          },
        },
      }),
    }),
  }),
});

export const { useDoctorListQuery, useDoctorDetailListMutation, useDoctorPatientListQuery, useUserTypeListQuery, useChangePasswordMutation , useChangeDoctorActiveStatusMutation, useChangePatientActiveStatusMutation, useTotalDoctorListDownloadLinkMutation } = doctorApiSlice;
