import React, { useEffect, useState } from "react";
import "./patient_detail.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import maleAvatar from "./../../assets/img/male_avatar.svg";
import femaleAvatar from "./../../assets/img/avatar.svg";
import { usePatientDetailListMutation } from "../../redux/reducers/patient/patientApiSlice";
import customFunction from "../../util/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";

const PatientDetail = () => {
  const [patientDetailList, { isLoading }] = usePatientDetailListMutation();
  let [detail, setDetail] = useState({});
  useEffect(() => {
    loadList();
  }, []);
  const { patientId } = useParams();

  const loadList = async () => {
    try {
      const res = await patientDetailList({ patient_id: patientId }).unwrap();
      if (!res?.status) {
        throw new Error(res?.msg);
      }
      if (res?.status && res?.result?.length === 0) {
        throw new Error("Patient detail list Not Available");
      }
      console.log(">>>>>>>>>", res.result.patient_list);

      setDetail({
        ...res.result.patient_list,
        pre_med_drug_type_values: res.result.patient_list.drug_data
          ? res.result.patient_list.drug_data.map((v, i) => v.drug_name)
          : "",
        comorbidity_type_values: res.result.patient_list.comorbity_data
          ? res.result.patient_list.comorbity_data.map(
              (v, i) => v.comorbodity_name
            )
          : "",
      });
    } catch (e) {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Something Went Wrong",
        text: e.message,
        showConfirmButton: true,
      });
    }
  };

  let page = (
    <div className="doctorDetail">
      <Sidebar />
      <div className="doctorDetailContainer">
        <Navbar />
        <div className="title">
          <h1>
            {detail && !isLoading
              ? `${customFunction.firstLetterUpper(detail.name)} Details`
              : "Patient Details"}
          </h1>
        </div>
        <div className="body">
          <div className="photoCard">
            <div className="left">
              <img
                src={
                  detail.gender && detail.gender.toLowerCase() === "male"
                    ? maleAvatar
                    : femaleAvatar
                }
                alt="img"
              />
            </div>
            <div className="middle">
              <ul>
                <li>
                  <label>Patient Name:</label>
                  <span>
                    {detail &&
                      !isLoading &&
                      customFunction.firstLetterUpper(detail.name)}
                  </span>
                </li>
                <li>
                  <label>Patient Age:</label>
                  <span>{detail && !isLoading && detail.age}</span>
                </li>
                <li>
                  <label>Patient Gender:</label>
                  <span>
                    {detail &&
                      !isLoading &&
                      customFunction.firstLetterUpper(detail.gender)}
                  </span>
                </li>
                <li>
                  <label>Patient Email:</label>
                  <span>{detail && !isLoading && detail.email}</span>
                </li>
                <li>
                  <label>Patient Contact No:</label>
                  <span>{detail && !isLoading && detail.mob_num}</span>
                </li>
              </ul>
            </div>
            <div className="right">
              <ul>
                <li>
                  <label>Language:</label>
                  <div className={`cellWithStatus admin`}>
                    <strong>
                      {detail && !isLoading && detail.prefer_language}
                    </strong>
                  </div>
                </li>
                <li>
                  <label>Patient Status:</label>
                  <div
                    className={`cellWithStatus ${
                      detail && !isLoading && detail.is_active === "Yes"
                        ? "active"
                        : "deactivate"
                    }`}
                  >
                    <strong>
                      {detail && !isLoading && detail.is_active === "Yes"
                        ? "Active"
                        : "Deactive"}
                    </strong>
                  </div>
                </li>
                <li>
                  <label>Notification Status:</label>
                  <div
                    className={`cellWithStatus ${
                      detail &&
                      !isLoading &&
                      detail.notification_is_active === "Yes"
                        ? "active"
                        : "deactivate"
                    }`}
                  >
                    <strong>
                      {detail &&
                      !isLoading &&
                      detail.notification_is_active === "Yes"
                        ? "Active"
                        : "Deactive"}
                    </strong>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <hr />
          <div className="detailCard">
            <div className="left">
              <h3>Personal & Communication Details</h3>
              <ul>
                <li>
                  <label>Patient Education:</label>
                  <span>
                    {detail &&
                      !isLoading &&
                      customFunction.firstLetterUpper(detail.edu_name)}
                  </span>
                </li>
                <li>
                  <label>Patient Occupation:</label>
                  <span>
                    {detail &&
                      !isLoading &&
                      customFunction.firstLetterUpper(detail.occupation_name)}
                  </span>
                </li>
                <li>
                  <label>Patient Monthly Income:</label>
                  {detail && !isLoading && (
                    <div className="money">
                      <FontAwesomeIcon
                        icon={faIndianRupeeSign}
                        className="icon"
                      />
                      <span>{detail.monthly_income}</span>
                    </div>
                  )}
                </li>
                <li>
                  <label>Patient Aadhaar No:</label>
                  <span>{detail && !isLoading && detail.aadhar}</span>
                </li>
                <li>
                  <label>Patient Address:</label>
                  <span>
                    {detail &&
                      !isLoading &&
                      customFunction.firstLetterUpper(detail.address)}
                  </span>
                </li>
                <li>
                  <label>Patient City:</label>
                  <span>
                    {detail &&
                      !isLoading &&
                      customFunction.firstLetterUpper(detail.city)}
                  </span>
                </li>
                <li>
                  <label>Patient State:</label>
                  <span>
                    {detail &&
                      !isLoading &&
                      customFunction.firstLetterUpper(detail.state)}
                  </span>
                </li>
                <li>
                  <label>Patient Country:</label>
                  <span>
                    {detail &&
                      !isLoading &&
                      customFunction.firstLetterUpper(detail.country)}
                  </span>
                </li>
              </ul>
            </div>
            <div className="right">
              <h3>Doctor & Hospital Details</h3>
              <ul>
                <li>
                  <label>Patient's Doctor:</label>
                  <span>
                    {detail &&
                      !isLoading &&
                      customFunction.firstLetterUpper(detail.doctor_name)}
                  </span>
                </li>
                <li>
                  <label>Patient's Doctor's Specialization:</label>
                  <span>
                    {detail &&
                      !isLoading &&
                      customFunction.firstLetterUpper(detail.specialization)}
                  </span>
                </li>
                <li>
                  <label>Patient's Doctor's Email:</label>
                  {detail && !isLoading && <span>{detail.doctor_email}</span>}
                </li>
                <li>
                  <label>Patient's Doctor Contact No:</label>
                  <span>{detail && !isLoading && detail.doc_mob_num}</span>
                </li>
                <li>
                  <label>Patient's Hospital Name:</label>
                  <span>
                    {detail &&
                      !isLoading &&
                      customFunction.firstLetterUpper(detail.hospital_name)}
                  </span>
                </li>
                <li>
                  <label>Patient's Hospital Address:</label>
                  <span>
                    {detail &&
                      !isLoading &&
                      customFunction.firstLetterUpper(detail.hospital_address)}
                  </span>
                </li>
                <li>
                  <label>Patient's Hospital Pin Code:</label>
                  <span>
                    {detail &&
                      !isLoading &&
                      customFunction.firstLetterUpper(detail.hospital_pincode)}
                  </span>
                </li>
                <li>
                  <label>Patient's Hospital Contact No:</label>
                  <span>
                    {detail &&
                      !isLoading &&
                      customFunction.firstLetterUpper(detail.hospital_no)}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <hr />
          <div className="historyCard">
            <h3>Patient Medical History</h3>
            <div className="historyDetailCard">
              <div className="left">
                <ul>
                  <li>
                    <label>Patient Headache Duration Month:</label>
                    <span>
                      {detail &&
                        !isLoading &&
                        `${detail.headache_duration_month} Months`}
                    </span>
                  </li>
                  <li>
                    <label>Patient Diagnosis Type Primary:</label>
                    <span>
                      {detail &&
                        !isLoading &&
                        customFunction.firstLetterUpper(detail.diagnosis_type)}
                    </span>
                  </li>
                  <li>
                    <label>Patient Diagnosis Type Primary Name:</label>
                    <span>
                      {detail &&
                        !isLoading &&
                        customFunction.firstLetterUpper(
                          detail.dia_pri_sub_type_name
                        )}
                    </span>
                  </li>
                  <li>
                    <label>Patient Preventive Medication:</label>
                    <span>
                      {detail &&
                        !isLoading &&
                        customFunction.firstLetterUpper(
                          detail.preventive_medication
                        )}
                    </span>
                  </li>
                  <li>
                    <label>Patient Preventive Drug Type:</label>
                    <span>
                      {detail &&
                        !isLoading && detail.pre_med_drug_type_values ? 
                        detail.pre_med_drug_type_values.join(", ") : ""}
                    </span>
                  </li>
                  <li>
                    <label>Patient Preventive Drug Other:</label>
                    <span>
                      {detail &&
                        !isLoading &&
                        detail.preventive_medication_drug_other}
                    </span>
                  </li>
                  <li>
                    <label>Patient Preventive Medication mg:</label>
                    <span>
                      {detail &&
                        !isLoading &&
                        `${detail.preventive_medication_dose} mg`}
                    </span>
                  </li>
                  <li>
                    <label>Patient Previous Medication:</label>
                    <span>
                      {detail && !isLoading && `${detail.medication}`}
                    </span>
                  </li>
                  <li>
                    <label>Patient's Nerve Status:</label>
                    <span>{detail && !isLoading && `${detail.nerve}`}</span>
                  </li>
                  <li>
                    <label>Patient's BMI:</label>
                    <span>{detail && !isLoading && `${detail.bmi}`}</span>
                  </li>
                  <li>
                    <label>Patient's Notication Reminder Opted:</label>
                    <span>
                      {detail &&
                        !isLoading &&
                        `${detail.pre_med_dose_1}, ${detail.pre_med_dose_2}, ${detail.pre_med_dose_3}`}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="right">
                <ul>
                  <li>
                    <label>Patient's Headache Duration Year:</label>
                    <span>
                      {detail &&
                        !isLoading &&
                        `${detail.headache_duration_year} Years`}
                    </span>
                  </li>
                  <li>
                    <label>Patient Diagnosis Type Secondary:</label>
                    <span>
                      {detail &&
                        !isLoading &&
                        customFunction.firstLetterUpper(
                          detail.diagnosis_secondary
                        )}
                    </span>
                  </li>
                  <li>
                    <label>Patient Diagnosis Type Primary Other:</label>
                    <span>
                      {detail &&
                        !isLoading &&
                        customFunction.firstLetterUpper(
                          detail.diagnosis_primary_other
                        )}
                    </span>
                  </li>
                  <li>
                    <label>Patient's Comorbidity:</label>
                    <span>
                      {detail &&
                        !isLoading &&
                        customFunction.firstLetterUpper(detail.comorbidity)}
                    </span>
                  </li>
                  <li>
                    <label>Patient Comorbidity Type:</label>
                    <span>
                      {detail &&
                        !isLoading && detail.comorbidity_type_values ? 
                        detail.comorbidity_type_values.join(", ") : ""}
                    </span>
                  </li>
                  <li>
                    <label>Patient Comorbidity Drug Other:</label>
                    <span>
                      {detail && !isLoading && detail.comorbidity_other}
                    </span>
                  </li>
                  <li>
                    <label>Patient's Preventive Medication Dose Time:</label>
                    <span>
                      {detail &&
                        !isLoading &&
                        detail.preventive_medication_dose_time}
                    </span>
                  </li>
                  <li>
                    <label>Patient's Alternate Medication:</label>
                    <span>{detail && !isLoading && detail.med_name}</span>
                  </li>
                  <li>
                    <label>Patient's Surgical Invervention:</label>
                    <span>{detail && !isLoading && detail.intervention}</span>
                  </li>
                  <li>
                    <label>Patient's Physician Rating:</label>
                    <span>
                      {detail && !isLoading && detail.physician_value}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return isLoading ? <Loader /> : page;
};

export default PatientDetail;
