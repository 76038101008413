import React from "react";
import "./sidebar.scss";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import AccountBoxRoundedIcon from "@mui/icons-material/AccountBoxRounded";
import PowerSettingsNewRoundedIcon from "@mui/icons-material/PowerSettingsNewRounded";
import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logOut } from "./../../redux/reducers/auth/authSlice";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserDoctor, faUserInjured, faFileArrowDown } from "@fortawesome/free-solid-svg-icons";

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logOut());
    navigate("/");
  };

  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/home" style={{ textDecoration: "none" }}>
          <span className="logo">Healthtrackerz</span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <Link to="/home" style={{ textDecoration: "none" }}>
            <li>
              <DashboardRoundedIcon className="icon" />
              <span>Dashboard</span>
            </li>
          </Link>
          <p className="title">LISTS</p>
          <Link to="/doctors" style={{ textDecoration: "none" }}>
            <li>
              <FontAwesomeIcon icon={faUserDoctor} className="icon" />
              <span>Doctors List</span>
            </li>
          </Link>
          <Link to="/patients" style={{ textDecoration: "none" }}>
            <li>
              <FontAwesomeIcon icon={faUserInjured} className="icon" />
              <span>Patients List</span>
            </li>
          </Link>
          <p className="title">REPORTS</p>
          <Link to="/download/reports" style={{ textDecoration: "none" }}>
            <li>
              <FontAwesomeIcon icon={faFileArrowDown} className="icon" />
              <span>Download Lists</span>
            </li>
          </Link>
          <p className="title">USER</p>
          <Link to="/profile" style={{ textDecoration: "none" }}>
            <li>
              <AccountBoxRoundedIcon className="icon" />
              <span>Profile</span>
            </li>
          </Link>
          <Link to="/changePassword" style={{ textDecoration: "none" }}>
            <li>
              <KeyRoundedIcon className="icon" />
              <span>Change Password</span>
            </li>
          </Link>
          <li onClick={handleLogout}>
            <PowerSettingsNewRoundedIcon className="icon" />
            <span>Logout</span>
          </li>
        </ul>
      </div>
      {/* <div className='bottom'>
            <div className='colorOption'></div>
            <div className='colorOption'></div>
        </div> */}
    </div>
  );
};

export default Sidebar;
