import React from "react";
import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";

const Datatable = ({
  listSource,
  listCols,
  listRows,
  isLoading,
  actionColWidth,
  handleStatus
}) => {


  const actionCol = [
    {
      field: "action",
      headerName: "Action",
      width: actionColWidth,
      renderCell: (params) => {
        let data = null;
        switch (listSource) {
          case "doctorList":
            data = (
              <div className="cellAction" key={params.row.id}>
                <Link
                  to={`/doctors/${params.row.phone_id}`}
                  style={{ textDecoration: "none" }}
                >
                  <div className="viewButton">Details</div>
                </Link>
                <Link
                  to={`/doctors/patients/list/${params.row.doctor_id}`}
                  style={{ textDecoration: "none" }}
                >
                  <div className="viewButton">Patient Lists</div>
                </Link>
                {params.row.is_active === "Yes" &&
                  params.row.permission_type !== "Admin" && (
                    <div className="deleteButton" aria-disabled={isLoading} onClick={() => handleStatus({doctorId: params.row.doctor_id, op: 'deactive'})}>Deactivate</div>
                  )}
                {params.row.is_active !== "Yes" &&
                  params.row.permission_type !== "Admin" && (
                    <div className="successButton" aria-disabled={isLoading} onClick={() => handleStatus({doctorId: params.row.doctor_id, op: 'active'})}>Activate</div>
                  )}
              </div>
            );
            break;
          case "doctorPatientList":
            data = (
              <div className="cellAction" key={params.row.id}>
                <Link
                  to={`/doctors/patient/${params.row.patient_id}`}
                  style={{ textDecoration: "none" }}
                >
                  <div className="viewButton">Details</div>
                </Link>
                {/* <Link
                  to={`/doctors/patients/list/${params.row.doctor_id}`}
                  style={{ textDecoration: "none" }}
                >
                  <div className="viewButton">Headache Lists</div>
                </Link> */}
                {params.row.is_active === "Yes" && (
                  <div className="deleteButton" aria-disabled={isLoading} onClick={() => handleStatus({patientId: params.row.patient_id, op: 'deactive'})}>Deactivate</div>
                )}
                {params.row.is_active !== "Yes" && (
                  <div className="successButton" aria-disabled={isLoading} onClick={() => handleStatus({patientId: params.row.patient_id, op: 'active'})}>Activate</div>
                )}
              </div>
            );
            break;

          case "patientList":
            data = (
              <div className="cellAction" key={params.row.id}>
                <Link
                  to={`/patients/${params.row.patient_id}`}
                  style={{ textDecoration: "none" }}
                >
                  <div className="viewButton">Details</div>
                </Link>
                {/* <Link
                    to={`/doctors/patients/list/${params.row.doctor_id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <div className="viewButton">Headache Lists</div>
                  </Link> */}
                {params.row.is_active === "Yes" && (
                  <div className="deleteButton" aria-disabled={isLoading} onClick={() => handleStatus({patientId: params.row.patient_id, op: 'deactive'})}>Deactivate</div>
                )}
                {params.row.is_active !== "Yes" && (
                  <div className="successButton" aria-disabled={isLoading} onClick={() => handleStatus({patientId: params.row.patient_id, op: 'active'})}>Activate</div>
                )}
              </div>
            );
            break;

          default:
            break;
        }
        return data;
      },
    },
  ];

  return (
    <div className="datatable" style={{ overflowWrap: false }}>
      <DataGrid
        rows={listRows}
        columns={listCols.concat(actionCol)}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        pageSizeOptions={[5, 10, 15, 20]}
        loading={isLoading}
        // checkboxSelection
        // autoPageSize
        //       sx={{
        //   "& .MuiDataGrid-virtualScroller": {
        //     overflowX: "scroll"
        //   }
        // }}
      />
    </div>
  );
};

export default Datatable;
