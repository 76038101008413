import React from 'react'
import './download_report.scss'
import Sidebar from '../../components/Sidebar/Sidebar'
import Navbar from '../../components/Navbar/Navbar'
import { useTotalDoctorListDownloadLinkMutation } from '../../redux/reducers/doctor/doctorApiSlice'
import Swal from 'sweetalert2'
import Loader from '../../components/Loader/Loader'
import {
  useTotalPatientDownloadLinkMutation,
  useTotalPatientHeadacheCharacteristicsListDownloadLinkMutation
} from '../../redux/reducers/patient/patientApiSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faFileArrowDown,
} from '@fortawesome/free-solid-svg-icons'
import { selectCurrentUser } from '../../redux/reducers/auth/authSlice'
import { useSelector } from 'react-redux'
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'

const DownloadReport = () => {
  const user = useSelector(selectCurrentUser)
  console.log(user)
  const [
    totalPatientDownloadLink,
    {
      isLoading: totalPatientDownloadLinkLoading,
      isSuccess: totalPatientDownloadLinkSuccess,
      isError: totalPatientDownloadLinkError
    }
  ] = useTotalPatientDownloadLinkMutation()
  const [
    totalDoctorListDownloadLink,
    {
      isLoading: totalDoctorListDownloadLinkLoading,
      isSuccess: totalDoctorListDownloadLinkSuccess,
      isError: totalDoctorListDownloadLinkError
    }
  ] = useTotalDoctorListDownloadLinkMutation()
  const [
    totalPatientHeadacheCharacteristicsListDownloadLink,
    {
      isLoading: totalPatientHeadacheCharacteristicsListDownloadLinkLoading,
      isSuccess: totalPatientHeadacheCharacteristicsListDownloadLinkSuccess,
      isError: totalPatientHeadacheCharacteristicsListDownloadLinkError
    }
  ] = useTotalPatientHeadacheCharacteristicsListDownloadLinkMutation()

  const createData = (name, operation) => {
    return { name, operation }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: '#7451f8',
      fontSize: 16,
      fontWeight: 500
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      fontWeight: 400,
      color: '#777696'
    }
  }))

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: '#fcfbff'
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0
    }
  }))

  const opName = {
    downloadTotalPatientList: 'downloadTotalPatientList',
    downloadTotalDoctorList: 'downloadTotalDoctorList',
    downloadTotalPatientHeadacheCharacteristicsList:
      'downloadTotalPatientHeadacheCharacteristicsList'
  }

  const opNameFileName = {
    downloadTotalPatientList: 'Download_Total_Patient_List',
    downloadTotalDoctorList: 'Download_Total_Doctor_List',
    downloadTotalPatientHeadacheCharacteristicsList:
      'Download_Total_Patient_Headache_Characteristics_List'
  }

  const rows = [
    createData(
      'Total Patients List',
      <span
        className='viewButton'
        aria-disabled={totalPatientDownloadLinkLoading}
        onClick={() => handleDownloadReport(opName.downloadTotalPatientList)}
      >
        Download
      </span>
    ),
    createData(
      'Total Doctors List',
      <span
        className='viewButton'
        aria-disabled={totalDoctorListDownloadLinkLoading}
        onClick={() => handleDownloadReport(opName.downloadTotalDoctorList)}
      >
        Download
      </span>
    ),
    createData(
      'Patient Headache Characteristics List',
      <span
        className='viewButton'
        aria-disabled={
          totalPatientHeadacheCharacteristicsListDownloadLinkLoading
        }
        onClick={() =>
          handleDownloadReport(
            opName.downloadTotalPatientHeadacheCharacteristicsList
          )
        }
      >
        Download
      </span>
    )
  ]

  const generateCurrentDate = () => {
    const today = new Date()
    const dd = today.getDate()
    const mm = today.getMonth() + 1
    const yyyy = today.getFullYear()
    return `${dd}-${mm}-${yyyy}`
  }

  const downloadGeneratedFile = async (operationName, fileLink) => {
    return new Promise((resolve, reject) => {
      let obj = {
        status: false,
        msg: ''
      }
      try {
        const link = document.createElement('a')
        link.download = `${opNameFileName[operationName]}_${
          user.doctor_name
        }_${generateCurrentDate()}`
        link.href = fileLink
        link.target = "_blank"
        link.click()
        obj.status = true
        obj.msg = 'File downloaded'
      } catch (e) {
        console.log(`Error ==>> ${e}`)
        obj.status = false
        obj.msg = e.message
      }
      resolve(obj)
    })
  }

  const handleDownloadReport = async operationName => {
    try {
      let linkData = null
      let r = null;
      switch (operationName) {
        case opName.downloadTotalPatientList:
          linkData = await handleDownloadAPIhit({ operationName })

          if (linkData && !linkData.status) {
            throw new Error(linkData.errorMsg)
          }
          r = await downloadGeneratedFile(
            operationName,
            linkData.data.file_link
          )
          if (!r.status) {
            throw new Error(r.msg)
          }
          break
        case opName.downloadTotalDoctorList:
          linkData = await handleDownloadAPIhit({ operationName })
          if (linkData && !linkData.status) {
            throw new Error(linkData.errorMsg)
          }
          r = await downloadGeneratedFile(operationName, linkData.data.file_link)
          if (!r.status) {
            throw new Error(r.msg)
          }
          break
        case opName.downloadTotalPatientHeadacheCharacteristicsList:
          linkData = await handleDownloadAPIhit({ operationName })
          if (linkData && !linkData.status) {
            throw new Error(linkData.errorMsg)
          }
          r = await downloadGeneratedFile(operationName, linkData.data.file_link)
          if (!r.status) {
            throw new Error(r.msg)
          }
          break

        default:
          break
      }
      if (r.status) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'File Download Status',
          text: `${opNameFileName[operationName]} file download successfully`,
          timer: 5000
        })
      }
    } catch (e) {
      console.log(`Error ==>> ${e}`)
      Swal.fire({
        position: 'top-end',
        icon: 'warning',
        title: 'Something Went Wrong',
        text: e.message,
        timer: 5000
      })
    }
  }

  const handleDownloadAPIhit = async ({ operationName }) => {
    return new Promise(async (resolve, reject) => {
      const obj = {
        status: false,
        errorMsg: '',
        data: null
      }
      let apiData = null
      try {
        switch (operationName) {
          case opName.downloadTotalPatientList:
            apiData = await totalPatientDownloadLink().unwrap()
            break
          case opName.downloadTotalDoctorList:
            apiData = await totalDoctorListDownloadLink().unwrap()
            break
          case opName.downloadTotalPatientHeadacheCharacteristicsList:
            apiData =
              await totalPatientHeadacheCharacteristicsListDownloadLink().unwrap()
            break
          default:
            break
        }

        if (apiData && apiData.status && apiData.status_code === 200) {
          obj.status = true
          obj.errorMsg = null
          obj.data = apiData.result
        } else {
          throw new Error(apiData.msg)
        }
      } catch (e) {
        console.log('Error ', e)
        obj.status = false
        obj.errorMsg = e.message
        obj.data = apiData
      }
      resolve(obj)
    })
  }

  let page = (
    <div className='downloadReport'>
      <Sidebar />
      <div className='downloadReportContainer'>
        <Navbar />
        <div className='title'>
          <h1>
            <FontAwesomeIcon icon={faFileArrowDown} className='icon' />
            &nbsp; Download Reports
          </h1>
        </div>
        <div className='body'>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label='customized table'>
              <TableHead>
                <TableRow>
                  <StyledTableCell>List Name</StyledTableCell>
                  <StyledTableCell align='right'>Operation</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map(row => (
                  <StyledTableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <StyledTableCell component='th' scope='row'>
                      {row.name}
                    </StyledTableCell>
                    <StyledTableCell align='right'>
                      {row.operation}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </div>
  )

  return (totalPatientDownloadLinkLoading || totalDoctorListDownloadLinkLoading || totalPatientHeadacheCharacteristicsListDownloadLinkLoading) ? <Loader /> : page;
}

export default DownloadReport
