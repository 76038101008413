import React from 'react'
import "./widget.scss";
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserDoctor, faUserGear, faUserInjured, faBrain, faLock } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';





const Widget = ({type,value}) => {
    let data;
    let linkStyle = { textDecoration: "none"};

    switch (type) {
        case "admin":
                data={
                    title: "TOTAL ADMINS",
                    isMoney: false,
                    link: "See all admins",
                    value: value,
                    linkURL: "/doctors",
                    icon: (
                        <FontAwesomeIcon 
                            icon={faUserGear} 
                            className='icon'
                            style={{
                                backgroundColor: "rgba(128,0,128,0.2)",
                                color: "purple"
                            }}  
                        />
                    ),
                    counterColour: {
                        // backgroundColor: "rgba(128,0,128,0.2)",
                        color: "purple"
                    }
                }
            break;
        case "doctor":
                data={
                    title: "TOTAL DOCTORS",
                    isMoney: false,
                    link: "View all doctors",
                    value: value,
                    linkURL: "/doctors",
                    icon: (
                        <FontAwesomeIcon 
                            icon={faUserDoctor} 
                            className='icon'
                            style={{
                                backgroundColor: "rgba(0,128,0,0.2)",
                                color: "green"
                            }}
                              
                        />
                    ),
                    counterColour: {
                        // backgroundColor: "rgba(128,0,128,0.2)",
                        color: "green"
                    }
                }
            break;    
        case "patient":
                data={
                    title: "TOTAL PATIENTS",
                    isMoney: false,
                    link: "View all patients",
                    value: value,
                    linkURL: "/patients",
                    icon: (
                        <FontAwesomeIcon 
                            icon={faUserInjured} 
                            className='icon'
                            style={{
                                backgroundColor: "rgba(218,165,32,0.2)",
                                color: "goldenrod"
                            }}
                        />
                    ),
                    counterColour: {
                        // backgroundColor: "rgba(128,0,128,0.2)",
                        color: "goldenrod"
                    }
                }
            break;
        case "headache":
                data={
                    title: "TOTAL HEADACHES EPISODES",
                    isMoney: false,
                    value: value,
                    link: "See all headaches",
                    linkURL: "",
                    icon: (
                        <FontAwesomeIcon 
                            icon={faBrain} 
                            className='icon'
                            style={{
                                backgroundColor: "#facec7",
                                color: "#f38674"
                            }}
                        />
                    ),
                    counterColour: {
                        // backgroundColor: "rgba(128,0,128,0.2)",
                        color: "#f38674"
                    }
                }
                linkStyle['pointerEvents'] = "none";
            break;


        default:
            break;
    }


  return (
    <div className='widget'>
        <div className='left'>
            {/* <div className='percentage positive'>
                <KeyboardArrowUpRoundedIcon />
                20%
            </div> */}
            <div className='titile'>
                <span className='titleText'>{data.title}</span>

            </div>
            {data.icon}
            <Link to={data.linkURL} style={linkStyle}>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "8px"}}>
                    <span className='link'>{data.link}</span>
                    {!data.linkURL && (
                        <FontAwesomeIcon icon={faLock}  style={{color: "#9e9e9e"}}/>
                    )}
                </div>
            </Link>
        </div>
        <div className='right'>
            <span className='counterText' style={data.counterColour}>{data.value}</span>
        </div>
        
    </div>
  )
}

export default Widget