import React, { useEffect, useState } from "react";
import "./doctor_detail.scss";
import Sidebar from "../../components/Sidebar/Sidebar";
import Navbar from "../../components/Navbar/Navbar";
import { useDoctorDetailListMutation } from "../../redux/reducers/doctor/doctorApiSlice";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import maleDoctor from "./../../assets/img/doctor_male.svg";
import femaleDoctor from "./../../assets/img/doctor_female.svg";
import { usePatientDetailListMutation } from "../../redux/reducers/patient/patientApiSlice";
import customFunction from "../../util/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import { selectCurrentUser } from "../../redux/reducers/auth/authSlice";
import { useSelector } from "react-redux";

const DoctorDetail = () => {
  const [doctorDetailList, { isLoading }] = useDoctorDetailListMutation();
  let [detail, setDetail] = useState({});
  useEffect(() => {
    loadList();
  }, []);

  const user = useSelector(selectCurrentUser);
  
  const loadList = async () => {
      try {
      const res = await doctorDetailList({ doctor_phone_id: "", __token: user._token }).unwrap();
      if (!res?.status) {
        throw new Error(res?.msg);
      }
      if (res?.status && res?.result?.length === 0) {
        throw new Error("Doctor detail list Not Available");
      }
      setDetail(res.result.doctor_detail);
      
    } catch (e) {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Something Went Wrong",
        text: e.message,
        showConfirmButton: true,
      });
    }
  };

  let page = (
    <div className="doctorDetail">
      <Sidebar />
      <div className="doctorDetailContainer">
        <Navbar />
        <div className="title">
          <h1>
            {detail && !isLoading
              ? `${customFunction.firstLetterUpper(detail.name)} Details`
              : "Doctor Details"}
          </h1>
        </div>
        <div className="body">
          <div className="photoCard">
            <div className="left">
              <img
                src={
                  detail.gender && detail.gender.toLowerCase() === "male"
                    ? maleDoctor
                    : femaleDoctor
                }
                alt="img"
              />
            </div>
            <div className="middle">
              <ul>
                <li>
                  <label>Doctor Name:</label>
                  <span>
                    {detail &&
                      !isLoading &&
                      customFunction.firstLetterUpper(detail.name)}
                  </span>
                </li>
                <li>
                  <label>Doctor Specialization:</label>
                  <span>{detail && !isLoading && detail.specialization}</span>
                </li>
                <li>
                  <label>Doctor Email:</label>
                  <span>{detail && !isLoading && detail.email}</span>
                </li>
                <li>
                  <label>Doctor Contact No:</label>
                  <span>{detail && !isLoading && detail.mob_num}</span>
                </li>
              </ul>
            </div>
            <div className="right">
              <ul>
                <li>
                  <label>Language:</label>
                  <div className={`cellWithStatus admin`}>
                    <strong>
                      {detail && !isLoading && "English"}
                    </strong>
                  </div>
                </li>
                <li>
                  <label>Doctor Status:</label>
                  <div
                    className={`cellWithStatus ${
                      detail && !isLoading && detail.is_active === "Yes"
                        ? "active"
                        : "deactivate"
                    }`}
                  >
                    <strong>
                      {detail && !isLoading && detail.is_active === "Yes"
                        ? "Active"
                        : "Deactive"}
                    </strong>
                  </div>
                </li>
                {detail && !isLoading && detail.permission_type === "Admin" && (
                <li>
                <label>Permission: </label>
                  <div
                    className={`cellWithStatus ${
                      detail.permission_type === "Admin"
                        ? "admin"
                        : "pending"
                    }`}
                  >
                    <strong>
                      {detail.permission_type === "Admin" ? "Admin" : ""}
                    </strong>
                  </div>
                </li>
              )}
              </ul>
            </div>
          </div>
          <hr />
          <div className="detailCard">
            <div className="left">
              <h3>Council Details</h3>
              <ul>
                <li>
                  <label>Council Name:</label>
                  <span>
                    {detail &&
                      !isLoading &&
                      customFunction.firstLetterUpper(detail.council_name)}
                  </span>
                </li>
                <li>
                  <label>Council Registration No:</label>
                  <span>
                    {detail &&
                      !isLoading &&
                      customFunction.firstLetterUpper(detail.council_reg_no)}
                  </span>
                </li>
                <li>
                  <label>Council City:</label>
                  <span>
                    {detail &&
                      !isLoading &&
                      customFunction.firstLetterUpper(detail.city)}
                  </span>
                </li>
              </ul>
            </div>
            <div className="right">
              <h3>Hospital Details</h3>
              <ul>
                <li>
                  <label>Hospital Name:</label>
                  <span>
                    {detail &&
                      !isLoading &&
                      customFunction.firstLetterUpper(detail.hospital_name)}
                  </span>
                </li>
                <li>
                  <label>Hospital Address:</label>
                  <span>
                    {detail &&
                      !isLoading &&
                      customFunction.firstLetterUpper(detail.hospital_address)}
                  </span>
                </li>
                <li>
                  <label>Hospital Pincode:</label>
                  {detail && !isLoading && <span>{detail.hospital_pincode}</span>}
                </li>
                <li>
                  <label>Hospital Contact No:</label>
                  <span>{detail && !isLoading && detail.hospital_no}</span>
                </li>
              </ul>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );

  return isLoading ? <Loader /> : page;
};

export default DoctorDetail;
