import React from 'react'
import "./topbar.scss";

const Topbar = () => {
  return (
    <div className='topbar'>
        <div className='top'>
            <span className='logo'>Admin Panel</span>
        </div>
    </div>
  )
}

export default Topbar